@import "~@angular/material/prebuilt-themes/indigo-pink.css";
@import "~bootstrap/dist/css/bootstrap.css";
@import '~ngx-toastr/toastr';

$background-color-blue : #99ccff;

* {
  font-family: 'ArialMT', 'Arial';
}

.cdk-overlay-pane {

  &.custom-attribute-dialog,
  &.modification-history-dialog,
  &.my-dialog {
    position: relative !important;
  }
}

.mat-menu-item {
  white-space: normal !important;
  line-height: 16px !important;
  outline: 1px solid #dee2e6 !important;

}

.custom-attribute-dialog {
  width: 70vw;
}

.modification-history-dialog {
  width: 80vw;
}

.close.mat-button {
  position: absolute;
  top: 0;
  right: 0;
  padding: 5px;
  line-height: 14px;
  min-width: auto;
}

.clearIcon {
  position: absolute;
  top: 0;
  right: 0;
  height: 40px;
  width: 40px;

  &:hover {
    cursor: pointer;
  }
}

.cursor-pointer {
  cursor: pointer;
}

.cursor-notallowed {
  cursor: not-allowed;
  pointer-events: none;
}

/* css for table grid  */
.mat-row {
  height: auto;

  &:hover {
    cursor: pointer;
    background-color: #ffffcc !important;
  }

  &:nth-child(even) {
    background-color: #f2f2f2;
  }

  &:nth-child(odd) {
    background-color: white;
  }
}

table {
  width: 100%;
}

.mat-header-cell {
  border-left: 1px solid white;
  justify-content: center;
}

.table-header-background {
  background-color: $background-color-blue;
  text-align: center;
}

.mat-sort-header-container {
  justify-content: center;
}

/*table css done*/

.required-symbol-color {
  font-size: 1.1rem;
  color: #db380f
}

.required-symbol {
  @extend .required-symbol-color;
  margin-left: 2%;
}

.blue-icon {
  color: #336699;
}

.green-icon {
  color: green;
}

.red-icon {
  color: red;
}

.grey-icon {
  color: rgb(119, 120, 121);
}

.purple-icon {
  color: rgb(120, 54, 126)
}

li:focus,
button:focus,
mat-icon:focus {
  outline: none !important;
}

/* top nav class */
.vertically-center-nav-component {
  margin-top: 20px;
  margin-bottom: 21.5px;
}

.side-nav-height-adjustment {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

.adjust-padding {
  padding: 0px 1vw;

  &:hover {
    cursor: pointer;
    background-color: white;
  }
}

.top-nav-mat-icon-size {
  height: 1rem !important;
  margin-right: 3px !important;
}

// top nav class done

.mat-dialog-container {
  padding: 0px ! important;
}

.my-success-dialog .mat-dialog-container {
  color: #fff !important;
  background: none !important;
  box-shadow: none !important;
  overflow: inherit !important;
}


/* styles related to tabs used in resources */
.mat-tab-label.mat-tab-label-active {
  background-color: $background-color-blue !important;
}

.mat-tab-label {
  border: 1px solid white;
  background-color: #e9e9e9;
  font-size: 10px;
  flex: 1 !important;
}

.mat-ink-bar {
  background-color: $background-color-blue !important;
}

////////

.mat-sidenav-content {
  background-color: white;
}

.mat-cell {
  border-left: 1px solid white;
  padding: 10px;
}

.mat-column-delete,
.mat-column-deleteIcon,
.mat-column-history,
.mat-column-historyIcon {
  text-align: center;
}

.heading {
  color: #336699;
}

.sub-heading {
  font-size: 16px;
  color: #336699;
}

.submit-button {
  background-color: #336699;
  color: white;
}

.reset-button {
  background-color: white;
  color: #336699;
  border: #336699;
}

.margin-left-1P {
  margin-left: 1% !important;
}

.margin-left-2P {
  margin-left: 2% !important;
}

.margin-left-3P {
  margin-left: 3% !important;
}

.margin-left-5P {
  margin-left: 5% !important;
}

.margin-left-10P {
  margin-left: 10% !important;
}

.margin-bottom-2P {
  margin-bottom: 2%;
}

.margin-left-10PX {
  margin-left: 10px;
}

.margin-right-8PX {
  margin-right: 8px;
}

.margin-right-10PX {
  margin-right: 10px;
}

.margin-top-10PX {
  margin-top: 10px;
}

.height-100vh {
  height: 100vh;
}

.width-2P {
  width: 2%;
}

.width-4P {
  width: 4%;
}

.width-5P {
  width: 5%;
}

.width-8P {
  width: 8%;
}

.width-9P {
  width: 9%;
}

.width-10P {
  width: 10%;
}

.width-20P {
  width: 20%;
}

.width-23P {
  width: 23%;
}

.width-25P {
  width: 25%;
}

.width-30P {
  width: 30%;
}

.width-36P {
  width: 36%;
}

.width-40P {
  width: 40%;
}

.width-50P {
  width: 50%;
}

.width-60P {
  width: 60%;
}

.width-70P {
  width: 70%;
}

.width-80P {
  width: 80%;
}

.width-90P {
  width: 90%;
}

.width-95P {
  width: 95%;
}

.width-100P {
  width: 100%;
}

.height-80PX {
  height: 80px;
}

.mat-placeholder-required.mat-form-field-required-marker {
  color: #db380f !important;
}

.header-text {
  color: #336699;
  font-family: Roboto, Helvetica Neue, sans-serif;
  font-size: 20px;
}

.import-button {
  background-color: #66CC66;
  color: azure;
}

.import-text {
  color: #336699;
  display: block;
  margin-top: 5px;
}

.mat-checkbox-checked.mat-accent .mat-checkbox-background {
  background-color: #336699;
}

.use_max_screen {
  height: 100%;
  width: 100%;
  position: relative;
}


.validation-indicator {
  height: 15px;
  width: 15px;
  border-radius: 50%;
}

.validation-circle-red {
  @extend .validation-indicator;
  margin: auto;
  background-color: red;
}

.validation-circle-yellow {
  @extend .validation-indicator;
  margin: auto;
  background-color: yellow;
}

.validation-circle-blue {
  @extend .validation-indicator;
  margin: auto;
  background-color: blue
}

.validation-circle-green {
  @extend .validation-indicator;
  margin: auto;
  background-color: green;
}

.circle-blue {
  @extend .validation-indicator;
  background-color: blue;
  display: inline-block;
}

.circle-yellow {
  @extend .validation-indicator;
  background-color: yellow;
  display: inline-block;
}

.circle-red {
  @extend .validation-indicator;
  background-color: red;
  display: inline-block;
}

.circle-green {
  @extend .validation-indicator;
  background-color: green;
  display: inline-block;
}

.position-relative {
  position: relative;
}

.highlight-record {
  background: #D6EBD6;
  border: 2px #66CC66 solid;
}

.mmc-business-card {
  height: 35px;
  width: 35px;
  color: #336699;
  opacity: .7;

  &:hover {
    opacity: 1 !important;
  }
}

.margin-bottom-1P {
  margin-bottom: 1%;
}

.sky-blue-background {
  background-color: rgba(153, 204, 255, 0.298039215686275)
}

.margin-top-2P {
  margin-top: 2%;
}

.margin-right-1EM {
  margin-right: 1em;
}

.add-selection {
  width: 100%;
  background-color: #66CC66;
  cursor: pointer;
  color: white
}

.inline-block {
  display: inline-block;
}

.validate-button,
.validate-button:hover {
  background-color: rgb(7, 247, 87);
  color: white;
}

.app-navbar {
  height: 4rem !important;
}

.screen-content {
  min-height: calc(100vh - 7rem);
  max-height: calc(100vh - 7rem);
  overflow: auto;
}

.active-filter {
  background-color: #ffffcc;
}

.clearPopup {
  position: absolute;
  right: 5px;
  top: 3px;

  &:hover {
    cursor: pointer;
  }
}

.back-to-screen {
  width: 100%;
  background-color: #336699;
  cursor: pointer;
  color: white;
}

.div-icons {
  visibility: hidden;
  display: flex;
  flex-direction: row;
  position: absolute;
  bottom: 25px;
  left: -20px;
}

tr:hover .div-icons {
  visibility: visible
}

.nav-bar-background-color {
  background-color: #e9e9e9;
}

.process-icon {
  width: 25px;
  height: 25px;
  background-color: #f2f2f2;
}

.import-row {
  display: flex;
  align-items: center;
}

.import-buttons {
  display: flex;
  justify-content: space-around;
}

.dummy-icon {
  background-color: #e9e9e9;
  visibility: hidden;
}

.home-icon-size {
  height: 100px;
  width: 100px;
}

.vertically-center-dummy-component {
  margin-top: 18px;
  margin-bottom: 19.5px;
}

.osano-cm-link {display: none;}
.osano-cm-widget {display:none;}